import {useCurrencyStore, useLanguageStore} from "@/shared/stores";
import {useMenuStore, useOrdersStore, useRoomsStore, useUserStore} from "@/console-new/stores";
import {getLocalDateFromServer, isTodayServerDate} from "@/shared/helpers/dates";
import {capitalizeFirstLetter} from "@/shared/helpers/strings";

let supportedQrVersion = 3;

export function getOrderTitle(order) {
	let title = "";
	let subtitle = null;

	let languageStore = useLanguageStore();
	let userStore = useUserStore();
	if(order.order_mode === "qr") {
		if(order.name !== null && order.table_number !== null) {
			subtitle = languageStore.getString("area_name", [order.table_number]);
			title = order.name;
		} else if(order.name !== null) {
			title = order.name;
			subtitle = order.room_table && order.room ? order.room_table.table_name+" - "+order.room.name : null;
		} else if(order.table_number !== null) {
			subtitle = order.room ? order.room.name : null;
			title = order.table_number;
		}
		if(order.import_title) {
			title = order.import_title+" - "+title;
		}
	} else if(order.order_mode === "delivery") {
		if(order.address !== null) {
			if(order.address.via !== null && order.address.civico !== null && order.address.comune !== null && order.address.zip !== null) {
				title = order.address.via + " " + order.address.civico + "\n" + order.address.comune + ", " + order.address.zip;
			} else {
				title = order.address.address;
			}
		} else {
			title = userStore.deliveryWord;
		}
		if(order.delivery_scheduled_date) {
			let isDateToday = isTodayServerDate(order.delivery_scheduled_date);
			if(isDateToday) {
				subtitle = languageStore.getString("today") + " " + (order.delivery_scheduled_date.getHours() < 10 ? "0"+order.delivery_scheduled_date.getHours() : order.delivery_scheduled_date.getHours()) + ":" + (order.delivery_scheduled_date.getMinutes() < 10 ? "0"+order.delivery_scheduled_date.getMinutes() : order.delivery_scheduled_date.getMinutes());
			} else {
				subtitle = (order.delivery_scheduled_date.getDate() < 10 ? "0"+order.delivery_scheduled_date.getDate() : order.delivery_scheduled_date.getDate()) + "/" + (order.delivery_scheduled_date.getMonth() < 9 ? "0"+(order.delivery_scheduled_date.getMonth()+1) : (order.delivery_scheduled_date.getMonth()+1)) + " " + (order.delivery_scheduled_date.getHours() < 10 ? "0"+order.delivery_scheduled_date.getHours() : order.delivery_scheduled_date.getHours()) + ":" + (order.delivery_scheduled_date.getMinutes() < 10 ? "0"+order.delivery_scheduled_date.getMinutes() : order.delivery_scheduled_date.getMinutes());
			}
		}
		if(order.import_title) {
			title = order.import_title+" - "+title;
		}
	} else if(order.order_mode === "takeaway") {
		let isDateToday = isTodayServerDate(order.takeaway_date);
		if(isDateToday) {
			title = languageStore.getString("today") + " " + (order.takeaway_date.getHours() < 10 ? "0"+order.takeaway_date.getHours() : order.takeaway_date.getHours()) + ":" + (order.takeaway_date.getMinutes() < 10 ? "0"+order.takeaway_date.getMinutes() : order.takeaway_date.getMinutes());
		} else {
			title = (order.takeaway_date.getDate() < 10 ? "0"+order.takeaway_date.getDate() : order.takeaway_date.getDate()) + "/" + (order.takeaway_date.getMonth() < 9 ? "0"+(order.takeaway_date.getMonth()+1) : (order.takeaway_date.getMonth()+1)) + " " + (order.takeaway_date.getHours() < 10 ? "0"+order.takeaway_date.getHours() : order.takeaway_date.getHours()) + ":" + (order.takeaway_date.getMinutes() < 10 ? "0"+order.takeaway_date.getMinutes() : order.takeaway_date.getMinutes());
		}
		title += " - ";
		if(order.user_name !== null) {
			title += order.user_name;
		} else {
			title += userStore.takeawayWord;
		}
		if(order.import_title) {
			title = order.import_title+" - "+title;
		}
		if(order.user_phone !== null) {
			subtitle = languageStore.getString("phone_number", [order.user_phone]);
		}
	} else if(order.order_mode === "counter") {
		title = order.name !== null ? order.name : languageStore.getString("direct_sale");
		if(order.import_title) {
			title = order.import_title+" - "+title;
		}
	}

	return {title: capitalizeFirstLetter(title), subtitle: subtitle ? capitalizeFirstLetter(subtitle) : null};
}

export function normalizeOrders(orders, items, itemsProducts, addresses, rates, multiPayments, plugins, allowGrouping = true, orderByPriority = false, mostRecentFirst = false, rooms = null) {

	let ordersObj = {};
	let addressesObj = {};
	let ratesObj = {};
	let roomsObj = {};
	let tablesObj = {};
	let pluginsObj = {};

	let ordersStore = useOrdersStore();

	addresses.forEach(address=> {
		address.address_id = Number(address.address_id);

		addressesObj[address.address_id] = address;
	});

	rates.forEach(rate=> {
		rate.courier_rate_id = Number(rate.courier_rate_id);

		ratesObj[rate.courier_rate_id] = rate;
	});

	let roomsStore = useRoomsStore();
	if(roomsStore.rooms) {
		roomsStore.rooms.forEach(room=> {
			roomsObj[room.room_id] = room;
		});
	}
	if(roomsStore.tables) {
		roomsStore.tables.forEach(table=> {
			tablesObj[table.room_table_id] = table;
		});
	}

	if(plugins) {
		plugins.forEach(plugin => {
			pluginsObj[plugin.plugin_id] = plugin;
		});
	}

	let isOldQrs = false;
	orders.forEach(order => {
		order.date = getLocalDateFromServer(order.date);
		order.delivery_date = order.delivery_date ? getLocalDateFromServer(order.delivery_date) : null;
		order.delivery_scheduled_date = order.delivery_scheduled_date ? getLocalDateFromServer(order.delivery_scheduled_date) : null;
		order.date_last_edit = order.date_last_edit ? getLocalDateFromServer(order.date_last_edit) : null;
		order.takeaway_date = order.takeaway_date ? getLocalDateFromServer(order.takeaway_date) : null;

		order.station_user_id = order.station_user_id ? Number(order.station_user_id) : null;
		order.address_id = order.address_id ? Number(order.address_id) : null;
		order.room_table_id = order.room_table_id ? Number(order.room_table_id) : null;
		order.courier_rate_id = order.courier_rate_id ? Number(order.courier_rate_id) : null;
		order.receipt_id = order.receipt_id ? Number(order.receipt_id) : null;
		order.epson_receipt_id = order.epson_receipt_id ? Number(order.epson_receipt_id) : null;
		order.invoice_id = order.invoice_id ? Number(order.invoice_id) : null;
		order.customer_person_id = order.customer_person_id ? Number(order.customer_person_id) : null;
		order.customer_company_id = order.customer_company_id ? Number(order.customer_company_id) : null;

		order.order_id = Number(order.order_id);
		order.business_id = Number(order.business_id);
		order.order_number_day = Number(order.order_number_day);
		order.total = Number(order.total);
		order.fee = Number(order.fee);
		order.course = Number(order.course);
		order.cover_charge_num = Number(order.cover_charge_num);
		order.cover_charge_value = Number(order.cover_charge_value);
		order.cover_charge_paid = Number(order.cover_charge_paid);
		order.delivery_costs_value = Number(order.delivery_costs_value);
		order.takeaway_costs_value = Number(order.takeaway_costs_value);
		order.subtotal_delta = Number(order.subtotal_delta);
		order.tip = Number(order.tip);
		order.qr_version = Number(order.qr_version);
		if(order.qr_version < supportedQrVersion && order.order_mode === "qr") {
			isOldQrs = true;
		}

		order.sms_sent = order.sms_sent === "1";
		order.fiscal_receipt = order.fiscal_receipt === "1";
		order.fiscal_request = order.fiscal_request === "1";
		order.paid = order.paid === "1";
		order.status = ordersStore.STATUS[order.status] ? order.status : "unknown";

		// calculated properties
		order.totalFormatted = useCurrencyStore().readableCurrencyValue(order.total, true, true, true);
		order.additionalCosts = (order.cover_charge_value*order.cover_charge_num) + order.delivery_costs_value + order.takeaway_costs_value + order.subtotal_delta + order.tip;
		order.grandTotal = order.total + order.additionalCosts;
		order.orderNumberDayFormatted = orderNumberDay(order);
		order.items = {};
		order.payments = [];
		order.paymentsPartial = 0;
		order.address = null;
		order.rate = null;
		order.user_name = null;
		order.user_phone = order.phone ? order.phone : null;
		order.user_email = order.notice_email ? order.notice_email : null;
		order.import_title = order.import_title ? order.import_title : null;

		order.plugin = null;
		if(order.plugin_id && pluginsObj.hasOwnProperty(order.plugin_id)) {
			order.plugin = pluginsObj[order.plugin_id];
		}

		let status = getOrderStatus(order);
		order.paymentMethodName = status.paymentMethodName;
		order.statusColor = status.statusColor;
		order.paymentColor = status.paymentColor;

		order.groupIdentifier = getGroupIdentifier(order, allowGrouping);

		if(order.address_id && addressesObj.hasOwnProperty(order.address_id)) {
			order.address = addressesObj[order.address_id];
		}

		if(order.courier_rate_id && ratesObj.hasOwnProperty(order.courier_rate_id)) {
			order.rate = ratesObj[order.courier_rate_id];
		}

		if(order.room_table_id && tablesObj.hasOwnProperty(order.room_table_id)) {
			order.room_table = tablesObj[order.room_table_id];

			if(order.room_table.room_id) {
				if(roomsObj.hasOwnProperty(order.room_table.room_id)) {
					order.room = roomsObj[order.room_table.room_id];
				} else if(rooms.hasOwnProperty(order.room_table.room_id)) {
					order.room = rooms[order.room_table.room_id];
				}
			}
		}
		ordersObj[order.order_id] = order;

	});

	if(isOldQrs !== ordersStore.isUsingOldQrs) {
		ordersStore.isUsingOldQrs = isOldQrs;
	}

	let menuStore = useMenuStore();

	items.forEach(item=> {
		item.order_item_id = Number(item.order_item_id);
		item.item_id = Number(item.item_id);
		item.order_id = Number(item.order_id);
		item.quantity = Number(item.quantity);
		item.category_id = Number(item.category_id);
		item.price = Number(item.price);
		item.redeemed = Number(item.redeemed);
		item.products = {};
		item.course = Number(item.course);

		item.menu_item = null;
		if(menuStore.items.hasOwnProperty(item.item_id)) {
			item.menu_item = menuStore.items[item.item_id];
		}

		if(ordersObj.hasOwnProperty(item.order_id)) {
			ordersObj[item.order_id].items[item.order_item_id] = item;
		}
	});

	itemsProducts.forEach(itemProduct=> {
		itemProduct.menu_item_list_product_id = Number(itemProduct.menu_item_list_product_id);
		itemProduct.order_item_id = Number(itemProduct.order_item_id);
		itemProduct.order_id = Number(itemProduct.order_id);
		itemProduct.quantity = Number(itemProduct.quantity);
		itemProduct.price = Number(itemProduct.price);
		itemProduct.order_number = Number(itemProduct.order_number);
		itemProduct.list_order_number = Number(itemProduct.list_order_number);

		if(ordersObj.hasOwnProperty(itemProduct.order_id) && ordersObj[itemProduct.order_id].items.hasOwnProperty(itemProduct.order_item_id)) {
			ordersObj[itemProduct.order_id].items[itemProduct.order_item_id].products[itemProduct.menu_item_list_product_id] = itemProduct;
		}
	});

	multiPayments.forEach(multiPayment=> {
		multiPayment.order_id = Number(multiPayment.order_id);
		multiPayment.amount = Number(multiPayment.amount);
		multiPayment.payment_amount = Number(multiPayment.payment_amount);
		multiPayment.payment_amount_tip = Number(multiPayment.payment_amount_tip);
		multiPayment.fee = Number(multiPayment.fee);
		multiPayment.receipt_id = multiPayment.receipt_id ? Number(multiPayment.receipt_id) : null;
		multiPayment.epson_receipt_id = multiPayment.epson_receipt_id ? Number(multiPayment.epson_receipt_id) : null;
		multiPayment.dutch_people = multiPayment.dutch_people ? Number(multiPayment.dutch_people) : null;
		multiPayment.fiscal_request = multiPayment.fiscal_request === "1";
		multiPayment.fiscal_receipt = multiPayment.fiscal_receipt === "1";
		multiPayment.date = getLocalDateFromServer(multiPayment.date);

		if(ordersObj.hasOwnProperty(multiPayment.order_id)) {
			ordersObj[multiPayment.order_id].payments.push(multiPayment);
			ordersObj[multiPayment.order_id].paymentsPartial += multiPayment.amount;
			multiPayment.orderNumberDayFormatted = ordersObj[multiPayment.order_id].orderNumberDayFormatted;
		} else {
			multiPayment.orderNumberDayFormatted = "#"+multiPayment.order_id;
		}
	});

	let ordersSeen = localStorage.getItem('ordersSeen');
	if(ordersSeen) {
		ordersSeen = JSON.parse(ordersSeen);
	}

	let ordersGroupsObj = {};
	orders.forEach(order=> {

		if(!order.user_name) {
			order.user_name = order.name;
		}

		let titles = getOrderTitle(order);
		order.title = titles.title;
		order.subtitle = titles.subtitle;

		order.items = Object.values(order.items);
		let maxCourse = 0;
		order.items.forEach(item=> {
			item.products = Object.values(item.products).sort((a,b)=> {
				if(a.list_order_number < b.list_order_number) {
					return -1;
				} else if(a.list_order_number > b.list_order_number) {
					return 1;
				} else {
					if(a.order_number < b.order_number) {
						return -1;
					} else if(a.order_number > b.order_number) {
						return 1;
					} else {
						return 0;
					}
				}
			});
			if(item.course > maxCourse) {
				maxCourse = item.course;
			}
		});
		order.maxCourse = maxCourse;

		if(!ordersGroupsObj.hasOwnProperty(order.groupIdentifier)) {
			order.groupLeader = true;
			ordersGroupsObj[order.groupIdentifier] = {
				orders: [order],
				groupIdentifier: order.groupIdentifier,
				date: order.date,
				type: order.type,
				room_table_id: order.room_table_id,
				order_mode: order.order_mode,
				status: order.status,
				paid: order.paid,
				paymentMethodName: order.paymentMethodName,
				statusColor: order.statusColor,
				paymentColor: order.paymentColor,
				title: order.title,
				subtitle: order.subtitle,
				room: order.room ? order.room : null,
				room_table: order.room_table ? order.room_table : null,
				sms_sent: order.sms_sent,
				priority: order.priority ? order.priority : 0,
				alert: !(ordersSeen && ordersSeen.hasOwnProperty(order.order_id)),
			};
		} else {
			if(order.date > ordersGroupsObj[order.groupIdentifier].date) {
				ordersGroupsObj[order.groupIdentifier].orders.forEach(o => o.groupLeader = false);
				order.groupLeader = true;
				ordersGroupsObj[order.groupIdentifier].date = order.date;
				ordersGroupsObj[order.groupIdentifier].title = order.title;
				ordersGroupsObj[order.groupIdentifier].subtitle = order.subtitle;
				ordersGroupsObj[order.groupIdentifier].sms_sent = order.sms_sent;
			} else {
				order.groupLeader = false;
			}

			if(!ordersGroupsObj[order.groupIdentifier].alert) {
				ordersGroupsObj[order.groupIdentifier].alert = !(ordersSeen && ordersSeen.hasOwnProperty(order.order_id));
			}

			ordersGroupsObj[order.groupIdentifier].orders.push(order);
		}
	});

	ordersGroupsObj = Object.values(ordersGroupsObj);
	if(orderByPriority) {
		ordersGroupsObj.sort((a,b)=> {
			return b.priority - a.priority;
		});
	} else {
		ordersGroupsObj.sort((a,b)=> {
			if(mostRecentFirst) {
				return b.date - a.date;
			} else {
				return a.date - b.date;
			}
		});
	}
	ordersGroupsObj.forEach(group=> {
		group.orders.sort((a,b)=> {
			return b.date - a.date;
		});
	});

	return ordersGroupsObj;

}

export function orderFiscalizedStatus(order) {
	if(order.type === "multi") {
		if(order.fiscal_request) {
			if(order.fiscal_receipt) {
				return "fiscalized";
			} else {
				return "pending";
			}
		}
		if(order.payments.length > 0) {
			let allFiscalized = true;
			for(let i = 0; i < order.payments.length; i++) {
				if(order.payments[i].type === "manual") {
					continue;
				}
				if(order.payments[i].fiscal_request) {
					if(!order.payments[i].fiscal_receipt) {
						return "pending";
					}
				} else {
					allFiscalized = false;
					break;
				}
			}
			if(allFiscalized) {
				return "fiscalized";
			} else {
				return "not_fiscalized";
			}
		} else {
			return "not_fiscalized";
		}
	} else if(order.type === "manual") {
		return "fiscalized";
	} else {
		if(order.fiscal_receipt && order.fiscal_request) {
			return "fiscalized";
		} else if(!order.fiscal_receipt && !order.fiscal_request) {
			return "not_fiscalized";
		} else {
			return "pending";
		}
	}
}

export function orderNumberDay(order) {
	let orderNumber = Number(order.order_number_day);
	if (orderNumber < 10) {
		orderNumber = "00" + orderNumber;
	} else if (orderNumber < 100) {
		orderNumber = "0" + orderNumber;
	} else {
		orderNumber = "" + orderNumber;
	}
	return "#"+orderNumber;
}

export function getOrderStatus(order) {

	let languageStore = useLanguageStore();
	let ordersStore = useOrdersStore();
	let userStore = useUserStore();
	let result = {
		paymentMethodName: languageStore.getString("unknown"),
		statusColor: "#8c8c8c",
		paymentColor: "#8c8c8c",
	};
	if(order.type === "klarna") {
		result.paymentMethodName = languageStore.getString("klarna");
		result.paymentColor = "#ffb3c7";
	} else if(order.type === "btc") {
		result.paymentMethodName = languageStore.getString("bitcoin");
		result.paymentColor = "#f2a900";
	} else if(order.type === "satispay") {
		result.paymentMethodName = languageStore.getString("satispay");
		result.paymentColor = "#F0483E";
	} else if(order.type === "manual") {
		result.paymentMethodName = languageStore.getString("manual");
		result.paymentColor = "#000000";
	} else if(order.type === "cash") {
		result.paymentMethodName = languageStore.getString("cash");
		result.paymentColor = "#E24B74";
	} else if(order.type === "multi") {
		result.paymentMethodName = languageStore.getString("multi_payment");
		result.paymentColor = "#a30db4";
	} else if(order.type === "wire") {
		result.paymentMethodName = languageStore.getString("wire");
		result.paymentColor = "#62D18D";
	} else if(order.type === "ticket") {
		result.paymentMethodName = languageStore.getString("ticket");
		result.paymentColor = "#62D18D";
	} else if(order.type === "paylater") {
		result.paymentMethodName = languageStore.getString("paylater");
		result.paymentColor = "#62D18D";
	} else if(order.type === "check") {
		result.paymentMethodName = languageStore.getString("check");
		result.paymentColor = "#62D18D";
	} else if(order.type === "external") {
		result.paymentMethodName = languageStore.getString("external");
		result.paymentColor = "#9a9393";
		if(order.plugin && order.plugin.color) {
			result.paymentColor = "#"+order.plugin.color;
			result.paymentMethodName = order.plugin.name;
		}
	} else {
		result.paymentMethodName = languageStore.getString("card");
		result.paymentColor = "#62D18D";
	}
	if(order.status === ordersStore.STATUS.completed) {
		if(order.type === "manual") {
			result.statusColor = "#000000";
			return result;
		} else {
			return result;
		}
	} else if(order.status === ordersStore.STATUS.canceled || order.status === ordersStore.STATUS.failed) {
		result.statusColor = "#835353";
		result.paymentMethodName = languageStore.getString("canceled");
		return result;
	} else {
		if(order.order_mode === "qr") {
			result.statusColor = 'var(--status-color-qr)';
		}  else if(order.order_mode === "delivery") {
			result.statusColor = 'var(--status-color-delivery)';
		} else if(order.order_mode === "takeaway") {
			result.statusColor = 'var(--status-color-takeaway)';
		} else if(order.order_mode === "counter") {
			result.statusColor = 'var(--status-color-counter)';
		}
	}

	return result;
}

export function getGroupIdentifier(order, allowGrouping, overrideStatus = null) {
	if(allowGrouping && order.order_mode === "qr" && order.room_table_id !== null && order.table_number !== null && order.status !== "completed") {
		return ""+order.room_table_id+order.table_number+order.type+(overrideStatus ? overrideStatus : order.status)+order.paid;
	} else {
		return ""+order.order_id;
	}
}
