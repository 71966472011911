
import {doubleFigure} from "@/shared/helpers/dates";
import {orderNumberDay} from "@/console-new/helpers/orders";
import {useCurrencyStore, useLanguageStore} from "@/shared/stores";
import {getNative, isNative} from "@/shared/helpers/webapp";
import {useNativeStore} from "@/console-new/stores";

let printerColumns = 32;

export function ordersPrinting(orders) {
	if(!isNative()) {
		return;
	}
	const nativeStore = useNativeStore();
	if(nativeStore.isPrintBig) {
		printerColumns = 48;
	}
	let alreadyPrinted = localStorage.getItem("AndroidAlreadyPrinted");
	if(alreadyPrinted !== null) {
		alreadyPrinted = JSON.parse(alreadyPrinted);
	} else {
		alreadyPrinted = [];
	}

	let printed = false;
	for(let i = 0; i < orders.length; i++) {
		if(!alreadyPrinted.includes(orders[i].order_id)) {
			let resultToPrint = ordersEscpos([orders[i]]);
			if(resultToPrint === null || resultToPrint === "") {
				continue;
			}
			getNative().print(resultToPrint);
			alreadyPrinted.push(orders[i].order_id);
			printed = true;
		}
	}
	if(alreadyPrinted.length > 500) {
		alreadyPrinted = alreadyPrinted.slice(alreadyPrinted.length - 100);
	}
	localStorage.setItem("AndroidAlreadyPrinted", JSON.stringify(alreadyPrinted));

	if(printed && getNative().playNewOrder) {
		getNative().playNewOrder();
	}

}

export function ordersEscpos(orders) {
	let result = "";
	let numOrders = orders.length;


	let excludedCategories = [];
	if(isNative()) {
		const nativeStore = useNativeStore();
		let androidExcludedCategories = nativeStore.excludedCategories;
		excludedCategories = androidExcludedCategories.map(category_id => Number(category_id));
	}
	if(excludedCategories.length > 0) {

		let newOrders = [];
		for(let i = 0; i < numOrders; i++) {
			let orderItems = orders[i].items.filter(item => !excludedCategories.includes(item.category_id));
			if(orderItems.length !== 0) {
				let orderFiltered = Object.assign(orders[i], {items: orderItems});
				newOrders.push(orderFiltered);
			}
		}
		orders = newOrders;
		numOrders = orders.length;

		if(numOrders === 0) {
			return null;
		}

	}


	const nativeStore = useNativeStore();
	let printTotal = nativeStore.isPrintTotal;

	let total = printTotal ? ordersTotal(orders) : 0;
	let partialTotal = printTotal ? ordersTotalFromItems(orders) : 0;
	let additionalCosts = printTotal ? orders.reduce((acc, order) => acc + order.additionalCosts, 0) : 0;

	for(let i = 0; i < numOrders; i++) {

		let firstOfMany = null;
		if(numOrders > 1) {
			firstOfMany = i === 0;
		}
		result += printOrder(orders[i], firstOfMany);

		if (i === numOrders-1) {

			let status = getPrintableOrderStatus(orders[i]);
			result += justifyCenter(bold(doubleSize(status)));
			if(printTotal) {
				let hasSubtotal = excludedCategories.length > 0 && partialTotal < total;
				if(hasSubtotal) {
					result += "\n"+justifyCenter(bold("Subtot parziale " + formatPriceString(partialTotal)));
				}
				if(additionalCosts !== 0) {
					if(!hasSubtotal) {
						result += "\n";
					}
					result += justifyCenter(bold("Subtot " + formatPriceString(total)));
					result += justifyCenter(bold("Supplementi " + formatPriceString(additionalCosts)));
				}
				result += justifyCenter(bold(doubleSize("Tot " + formatPriceString(additionalCosts))))+"\n";
			}
		}

		result += "\n";

	}

	result += "\n\n\n";

	return result;

}

export function printReport(business, rows, date) {
	const languageStore = useLanguageStore();

	let result = "";
	result += justifyCenter(bold(business.name));
	if(business.address) {
		if(business.address.via && business.address.civico && business.address.comune && business.address.zip && business.address.provincia_codice) {
			result += justifyCenter(bold(business.address.via + " " + business.address.civico));
			result += justifyCenter(bold(business.address.zip + " " + business.address.comune + " (" + business.address.provincia_codice + ")"));
		} else if(business.address.address) {
			result += justifyCenter(bold(business.address.address));
		}
	}
	if(business.vat) {
		result += justifyCenter(bold("P.IVA: " + business.vat));
	}
	result += "\n";
	result += justifyCenter(bold(doubleSize("CHIUSURA")));
	result += justifyCenter(bold(doubleSize(date)));
	result += "\n";
	result += justifyCenter(bold(doubleSize("Riepilogo")));
	let total = 0;
	let modes = Object.keys(rows.total).sort();
	for(let i = 0; i < modes.length; i++) {
		let key = modes[i];
		let value = Number(rows.total[key] || 0);
		result += justifySpaceBetween(languageStore.getString(key), value.toFixed(2).toLocaleString(["it-IT"]), false, true);
		total += value;
	}
	result += justifySpaceBetween("Tot", total.toFixed(2).toLocaleString(["it-IT"]), true, true);
	result += "\n";
	result += lineSeparator();
	result += "\n\n";
	result += justifyCenter(bold(doubleSize("Dettaglio")));
	for(let mode in rows) {
		if(mode === "total") {
			continue;
		}
		const modeKey = getModeKeys(mode);
		result += justifyCenter(bold(languageStore.getString(modeKey)));
		let total = 0;
		let modes = Object.keys(rows[mode]).sort();
		for(let i = 0; i < modes.length; i++) {
			let key = modes[i];
			let value = Number(rows[mode][key] || 0);
			result += justifySpaceBetween(languageStore.getString(key), value.toFixed(2).toLocaleString(["it-IT"]), false, true);
			total += value;
		}
		result += justifySpaceBetween("Tot", total.toFixed(2).toLocaleString(["it-IT"]), true, true);
		result += "\n";
	}

	result += "\n\n\n";
	return result;
}

export function getModeKeys(mode) {
	if(mode === "qr") {
		return "qr_code_tables"
	} else if(mode === "counter") {
		return "counter_sale"
	}
	return mode;
}

export function printOrder(order, firstOfMany) {
	let result = "";

	let convertedDate = order.date;
	let timePart = doubleFigure(convertedDate.getHours()) + ":" + doubleFigure(convertedDate.getMinutes());
	result += justifySpaceBetween(orderNumberDay(order), timePart, false, true);

	if(firstOfMany === true || firstOfMany === null) {
		let titles = getPrintableOrderTitle(order);
		if(titles.title instanceof Array) {
			for(let i = 0; i < titles.title.length; i++) {
				result += justifyCenter(bold(doubleSize(replaceUnprintableChars(titles.title[i]))));
			}
		} else {
			result += justifyCenter(bold(doubleSize(replaceUnprintableChars(titles.title))));
		}
		if(titles.subtitle) {
			if(titles.subtitle instanceof Array) {
				for(let i = 0; i < titles.subtitle.length; i++) {
					result += justifyCenter(bold(replaceUnprintableChars(titles.subtitle[i])));
				}
			} else {
				result += justifyCenter(bold(replaceUnprintableChars(titles.subtitle)));
			}
		}
	} else {
		result += justifyCenter(bold("Altro ordine"));
	}

	const nativeStore = useNativeStore();
	let doubleSizeItems = nativeStore.isFontDoubleSize;
	let fixedSizeRight = nativeStore.isFixedSizeRight;

	result += lineSeparator();

	for(let i = 0; i < order.items.length; i++) {
		let item = order.items[i];
		result += "\n";
		if(fixedSizeRight) {
			result += fixedRightColumn(replaceUnprintableChars(item.name), "x"+item.quantity, doubleSizeItems, true);
		} else {
			result += justifySpaceBetween(replaceUnprintableChars(item.name), "x"+item.quantity, doubleSizeItems, true);
		}

		for(let y = 0; y < item.products.length; y++) {
			let productName = item.products[y].product_name;
			let quantity = item.products[y].quantity;
			if(fixedSizeRight) {
				result += fixedRightColumn("  + "+replaceUnprintableChars(productName), "x"+quantity, doubleSizeItems, true);
			} else {
				result += justifySpaceBetween("  + "+replaceUnprintableChars(productName), "x"+quantity, doubleSizeItems, true);
			}
		}

		if(item.note) {
			result += bold("Note prodotto: "+replaceUnprintableChars(item.note));
			result += "\n";
		}

		result += lineSeparator();
	}

	if(order.notes) {
		result += "\n";
		result += justifyCenter(bold("Note"))+"\n";
		result += justifyCenter(bold(replaceUnprintableChars(order.notes)));
	}

	result += "\n\n";
	return result;
}

export function getPrintableOrderTitle(order) {
	let title = "";
	let subtitle = null;

	if(order.order_mode === "qr") {
		subtitle = [];
		if(order.name !== null && order.table_number !== null) {
			subtitle.push("Area " + order.table_number);
			title = order.name;
		} else if(order.name !== null) {
			title = order.name;
		} else if(order.table_number !== null) {
			title = order.table_number;
		}
		if(typeof order.room !== "undefined" && order.room !== null && order.room.name !== null) {
			subtitle.push(order.room.name);
		}
	} else if(order.order_mode === "delivery") {
		subtitle = [];
		title = [];
		if(order.address !== null) {
			if(order.address.via !== null && order.address.civico !== null && order.address.comune !== null && order.address.zip !== null) {
				title.push(order.address.via + " " + order.address.civico);
				title.push(order.address.comune + ", " + order.address.zip);
			} else {
				title.push(order.address.address);
			}
			if(order.address.details !== null) {
				subtitle.push(order.address.details);
			}
		} else {
			title.push("Delivery");
		}
		if(order.user_name !== null) {
			subtitle.push("Nome: "+order.user_name);
		}
		if(order.user_phone !== null) {
			subtitle.push("Tel: "+order.user_phone);
		}
		if(order.delivery_scheduled_date) {
			let orderDate = order.delivery_scheduled_date;
			title.push("Cons "+(orderDate.getHours() < 10 ? "0"+orderDate.getHours() : orderDate.getHours())+":"+(orderDate.getMinutes() < 10 ? "0"+orderDate.getMinutes() : orderDate.getMinutes()) +
				" "+orderDate.getDate()+"/"+(orderDate.getMonth()+1));
		}
	} else if(order.order_mode === "takeaway") {
		title = [];
		if(order.user_name !== null) {
			title.push(order.user_name);
		} else {
			title.push("Asporto");
		}
		if(order.takeaway_date) {
			let orderDate = order.takeaway_date;
			title.push("Rit "+(orderDate.getHours() < 10 ? "0"+orderDate.getHours() : orderDate.getHours())+":"+(orderDate.getMinutes() < 10 ? "0"+orderDate.getMinutes() : orderDate.getMinutes()) +
				" "+orderDate.getDate()+"/"+(orderDate.getMonth()+1));
		}

		if(order.user_phone !== null) {
			subtitle = "Tel: " + order.user_phone;
		}
	} else if(order.order_mode === "counter") {
		title = "Vendita diretta";
	}

	return {title: title, subtitle: subtitle};
}

export function ordersTotal(orders) {
	let result = 0;
	orders.forEach((order) => {
		result += Number(order.total);
	});
	return result;
}

export function ordersTotalFromItems(orders) {
	let result = 0;
	orders.forEach((order) => {
		order.items.forEach((item) => {
			result += Number(item.price) * Number(item.quantity);

			item.products.forEach((itemProduct) => {
				result += Number(itemProduct.price) * Number(itemProduct.quantity);
			});
		});
	});
	return result;
}

export function getPrintableOrderStatus(order) {
	let status = "Sconosciuto";
	if (order.paid) {
		status = "Pagato";
		if (order.type === "btc") {
			status = "Pagato Bitcoin";
		} else if (order.type === "satispay") {
			status = "Pagato Satispay";
		} else if (order.type === "klarna") {
			status = "Pagato Klarna";
		} else if (order.type === "paylater") {
			status = "Non riscosso";
		}
	} else {
		status = "** Non pagato **";
	}
	return status.toUpperCase();
}

export function bold(text) {
	return "\x1b\x45\x0f" + text + "\x1b\x45\x00";
}

export function justifyCenter(text) {
	return "\x1b\x61\x01" + text + "\n\x1b\x61\x00";
}

export function doubleSize(text) {
	return "\x1d\x21\x11" + text + "\x1d\x21\x00";
}

export function lineSeparator() {
	return "-".repeat(printerColumns);
}

export function justifySpaceBetween(left, right, doubleHeight, isBold = false) {
	let lineLength = printerColumns;
	if (doubleHeight) {
		lineLength /= 2;
	}
	let halfLineLen = lineLength / 2;
	let maxLeftLen = halfLineLen;
	if (right.length < maxLeftLen) {
		maxLeftLen = lineLength - right.length - 3;
	}
	let maxRightLen = halfLineLen;
	if (left.length < maxRightLen) {
		maxRightLen = lineLength - left.length - 3;
	}
	if (left.length > maxLeftLen) {
		left = left.substring(0, maxLeftLen) + "..";
	}
	if (right.length > maxRightLen) {
		right = right.substring(0, maxRightLen) + "..";
	}
	let spaceBetweenStrings = lineLength - left.length - right.length;
	if (spaceBetweenStrings < 0) {
		spaceBetweenStrings = 0;
	}
	let stringToPrint = left + " ".repeat(spaceBetweenStrings) + right;
	if(isBold) {
		stringToPrint = bold(stringToPrint);
	}
	return doubleHeight ? doubleSize(stringToPrint)+"\n" : stringToPrint+"\n";
}

export function fixedRightColumn(left, right, doubleHeight, isBold = false) {

	let lineLength = printerColumns;
	if (doubleHeight) {
		lineLength = Math.floor(lineLength / 2);
	}

	let rightLen = right.length + 1;
	let leftLen = lineLength - rightLen;
	if (rightLen > lineLength) {
		rightLen = lineLength - 1;
		leftLen = 1;
	}

	let leftWhitespacesCount = 0;
	for (let i = 0; i < left.length; i++) {
		if (left[i] === ' ') {
			leftWhitespacesCount++;
		} else {
			break;
		}
	}
	if (leftWhitespacesCount > 0) {
		leftWhitespacesCount += 2;
	}

	let leftParts = [];
	let lCopy = left;
	let firstPart = true;
	if (!(lCopy.length > leftLen - leftWhitespacesCount && lCopy.length <= leftLen)) {
		while (lCopy.length > leftLen - leftWhitespacesCount) {
			let prefix = '';
			if (!firstPart) {
				prefix = ' '.repeat(leftWhitespacesCount);
			}
			firstPart = false;
			leftParts.push(prefix + lCopy.substring(0, leftLen - prefix.length));
			lCopy = lCopy.substring(leftLen - prefix.length);
		}
	}
	let prefix = '';
	if (!firstPart) {
		prefix = ' '.repeat(leftWhitespacesCount);
	}
	leftParts.push(prefix + lCopy + ' '.repeat(leftLen - prefix.length - lCopy.length));

	let result = "";
	for (let i = 0; i < leftParts.length; i++) {
		let leftPart = leftParts[i];
		let rightPart = ' '.repeat(rightLen);
		if (i === leftParts.length - 1) {
			rightPart = ' ' + right;
		}
		let d = 1;
		if (doubleHeight) {
			d = 2;
		}
		let stringToPrint = leftPart + rightPart;
		if(isBold) {
			stringToPrint = bold(leftPart) + bold(rightPart);
		}
		stringToPrint = doubleHeight ? doubleSize(stringToPrint) : stringToPrint;
		result += stringToPrint + "\n";
	}

	return result;
}

function formatPriceString(price) {
	return useCurrencyStore().dbToActualValue(price).toFixed(2).replace(".",",") + " " + useCurrencyStore().currCurrency.code;
}

const replacements = {
	"£": "GBP",
	"$": "USD",
	"€": "EUR",
	"@": "A",
	"à": "a",
	"ä": "a",
	"è": "e",
	"é": "e",
	"ë": "e",
	"ì": "i",
	"ï": "i",
	"ò": "o",
	"ö": "o",
	"ù": "u",
	"ü": "u",
	"À": "A",
	"Ä": "A",
	"È": "E",
	"É": "E",
	"Ë": "E",
	"Ì": "I",
	"Ï": "I",
	"Ò": "O",
	"Ö": "O",
	"Ù": "U",
	"Ü": "U",
	"°": "o",
	"§": "S",
	"¥": "Y",
	"¢": "c",
	"ß": "ss",
	"æ": "ae",
	"œ": "oe"
};

export function replaceUnprintableChars(text) {
	const pattern = new RegExp(Object.keys(replacements).map((key) => {
		return key.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	}).join("|"), "g");

	return text.replace(pattern, (match) => {
		return replacements[match] || match; // replace or leave unchanged
	}).replaceAll(/[^\x20-\x7E]/g, "");
}